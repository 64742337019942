import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { groupUserUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import Paginator, { PageState } from 'primevue/paginator'

export default {
  components: {
    Paginator
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $mapAll, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const test = ref('')
    const data = ref([]) as any
    const expandedRowGroups = ref([]) as any
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const currentId = ref(null) as any
    const op = ref()
    const inputSearch = ref('')

    const filters = reactive({
      search: '',
      pageNumber: 1,
      pageSize: $numbers.totalItemPerPage,
      totalRecords: 0,
    })

    const redirectEditPermission = () => {
      router.push({ name: 'master-grup-user-permissions', params: { id: currentId.value } })
    }

    const redirectAdd = () => {
      currentId.value = null
      router.push({ name: 'master-grup-user-baru' })
    }

    const redirectEdit = () => {
      router.push({ name: 'master-grup-user-edit', params: { id: currentId.value } })
    }

    const showDropDown = (evt: any, idData: any) => {
      currentId.value = encryptDecriptMethods.encrypt(idData)
      op.value.toggle(evt)
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      filters.search = inputSearch.value
      const fixedFilters = {
        ...filters,
        filterField: ['name'],
      }
      const response = await groupUserUseCase.getAll(fixedFilters)
      if (!response.error) {
        filters.totalRecords = response.result.Count
        // const map = response.result
        data.value = response.result.Data
      }
      store.dispatch('hideLoading')
    }

    const onPage = (evt: PageState) => {
      filters.pageNumber = evt.page + 1
      getAllData()
    }

    const onRowExpand = ($evt: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === $evt.data) {
          data.value[i].group.ShowAction = true
        }
      }
    }

    const onRowGroupCollapse = ($evt: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === $evt.data) {
          data.value[i].group.ShowAction = false
        }
      }
    }

    const changeAll = (id: number, val: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].Id === id) {
          data.value[i].Write = val
          data.value[i].Read = val
          data.value[i].Update = val
          data.value[i].Delete = val
          break
        }
      }
    }

    const changeRead = (id: number, val: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].Id === id) {
          data.value[i].All = (val) ? data.value[i].All : val
          data.value[i].Write = (val) ? data.value[i].Write : val
          data.value[i].Read = val
          data.value[i].Update = (val) ? data.value[i].Update : val
          data.value[i].Delete = (val) ? data.value[i].Delete : val
          break
        }
      }
    }

    const changeCUD = (id: number, val: any, type: string) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].Id === id && val) {
          if (!data.value[i].Read) {
            data.value[i][type] = false
            $toast.add({
              severity: 'error', detail: 'Tidak dapat diaktifkan jika read tidak dicentang', group: 'bc', life: 3000
            })
          }
          if (data.value[i].Write && data.value[i].Read && data.value[i].Update && data.value[i].Delete) {
            data.value[i].All = true
          }
          break
        } else if (!val && data.value[i].Id === id) {
          data.value[i].All = false
          break
        }
      }
    }

    const changeShowAction = (id: number, status: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === id) {
          data.value[i].group.ShowAction = status
        }
      }
    }

    const expandedGroup = (id: number) => {
      const check = expandedRowGroups.value.filter((val: number) => val === id).length

      if (check > 0) {
        expandedRowGroups.value = []
        changeShowAction(id, false)
      } else {
        if (expandedRowGroups.value.length > 0) {
          changeShowAction(expandedRowGroups.value[0], false)
        }
        changeShowAction(id, true)
        expandedRowGroups.value = [id]
      }
    }

    const collapsedGroup = (id: number) => {
      expandedRowGroups.value = []
      changeShowAction(id, false)
    }

    const onSearch = () => {
      if (inputSearch.value.length >= 3 || inputSearch.value.length === 0) {
        filters.pageNumber = 1
        getAllData()
      }
    }

    onMounted(() => {
      // changeTdColSpan()
      getAllData()
    })

    return {
      data,
      test,
      expandedRowGroups,
      op,
      route,
      inputSearch,
      onPage,
      showDropDown,
      onRowExpand,
      onRowGroupCollapse,
      changeAll,
      changeRead,
      changeCUD,
      expandedGroup,
      collapsedGroup,
      redirectAdd,
      redirectEdit,
      getAllData,
      redirectEditPermission,
      filters,
      onSearch
    }
  }
}
